import React, { Fragment } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import FlexibleContent from "../components/FlexibleContent"
import Divider from "../components/Divider"
import Spacer from "../components/Spacer"

const StyledJobLink = styled(Link)`
  color: #f03a85 !important;
  display: block;
  &:hover {
    font-weight: 500;
  }
`

export default function Vacancies() {
  const {
    wpPage: {
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
      flexibleGeneralContentFields: { content: pageContent },
    },
    allWpVacancy: { nodes: allVacancies },
  } = useStaticQuery(
    graphql`
      query {
        wpPage(title: { eq: "Vacancies" }) {
          seo {
            metaDesc
            title
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          pageHeaderFields {
            headerContent
          }
          flexibleGeneralContentFields {
            ...FlexibleContent
          }
        }
        allWpVacancy {
          nodes {
            slug
            title
          }
        }
      }
    `
  )

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ lg: 48 }} />
      <FlexibleContent content={pageContent} />
      {!!allVacancies.length && (
        <>
          <Spacer size={32} when={{ lg: 48 }} />
          <Divider />
          <Spacer size={32} when={{ lg: 48 }} />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Current Openings</h2>
                <Spacer size={32} />
                {allVacancies.map(({ title, slug }, index) => (
                  <Fragment key={slug}>
                    <StyledJobLink to={`/vacancies/${slug}/`}>
                      {title}
                    </StyledJobLink>
                    {index !== allVacancies.length - 1 && <Spacer size={16} />}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
    </>
  )
}
